@import '../../assets/styles/vars';

.home-layout {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 45px;
  gap: 20px;

  @media screen and (max-width: $smWidthBreakpoint) {
    padding: 16px;
  }
}

.node-list {
  border-radius: 12px;
  flex: 1;
  display: flex;
  padding: 32px;
  justify-content: center;
  align-items: center;
  position: relative;

  canvas {
    height: calc(100vh - 180px) !important;
  }
}

.title-controller {
  flex-direction: row;

  @media screen and (max-width: $smWidthBreakpoint) {
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: flex-start;
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  transition: 0.4s !important;
  transition-duration: 0.4s !important;
}

.node-card {
  width: 230px;
  height: 100px;
  border-radius: 10px;
  padding: 10px;
  overflow: unset;

  border: 1px solid rgb(224, 224, 224);
  text-align: left;
  position: relative;
  z-index: 0;

  p {
    font-size: 15px;
    color: #555;
    letter-spacing: 0.24px;
    margin-top: 20px;
  }
}

.field-icon {
  width: 38px;
  height: 38px;
  background-color: #e4e4e6;
  border-radius: 50%;
  border: 2px solid white;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -16px;
  z-index: 10000;

  svg {
    path {
      fill: #a9a9b5;
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 10px;

  button {
    padding: 2px 6px;
    height: 20px;
    border: 1px solid #e4e4e6;
    border-radius: 5px ;
    font-size: 13px;
    gap: 1px;
  }
}

.options {
  position: absolute;
  top: 10px;
  right: 15px;
}

.chart-wrapper {
  width: 100% !important;
  cursor: all-scroll;
  height: calc(100vh - 220px) !important;
}

.zoom-controls {
  position: absolute;
  top: 10px;
  right: 10px;

  display: flex;
  gap: 10px;
  z-index: 2;

  button {
    cursor: pointer !important;
    background-color: white;
    border-radius: 10px;
    font-size: 13px;
    height: 40px;
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  gap: 1px;

  font-size: 13px;
  letter-spacing: 0.24px;
}

.package-icon {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}