@import '../../assets/styles/vars';

.home-layout{
    flex: 1;
    display: flex;
    flex-direction: column;


    main{
        height: 100%;
        flex: 1;
        margin: 60px 0px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        @media screen and (max-width: $smWidthBreakpoint) {
            margin: 10px 0px; 
        }
    }
}

.explore-button {
    width: 200px;
    color: white;

    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: 0px 0px 10px 1px #00000044;


    @media screen and (max-width: $smWidthBreakpoint) {
        position: fixed;
    }

}

.content {
    width: 100%;
    height: calc(100vh - 354px);
    overflow-y: auto;
    max-width: 750px;
    padding: 24px;
    padding-bottom: 56px;
    padding-top: 56px;
    background-color: #6bc4d228;
    border-radius: 16px;
    position: relative;

    @media screen and (max-width: $smWidthBreakpoint) {
        padding-top: 50px;
        border-radius: 0;
        height: 100%;
        overflow: hidden;
    }
}

.button-controller{
    justify-content: center;
    gap: 20px;
}

.center-aligner{
    flex: 1;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.input-controller{
    padding: 18px 16px;
    width: fit-content;
    background: $dark;
    box-shadow: $shadow;
    border-radius: 14px;
    position: relative;
    height: fit-content;

    align-items: flex-start;
    display: flex;
    gap: 20px;
    flex-direction: column;

    @media screen and (max-width:600px ) {
        width: auto !important;
    }

    input {
        width: 280px;

        background: #252525;
        border: 2px solid rgba(113, 113, 113, 0.2);
        box-shadow: $shadow;
        border-radius: 8px;
    }

    .check-btn {
        width: 170px;
        max-width: 182px;
        padding: 16px 40px;
        background: $dark-grey;
        box-shadow: $shadow;
        border-radius: 8px;
        color: white;

        @media screen and (max-width: 600px ) {
            max-width: 100%;
            width: 100%;
        }
    }
}

.claiming-button{
    height: 64px;
    padding: 10px 60px;
    font-size: 18px;
    font-weight: 400;

    @media screen and (max-width:900px ) {
        width: 100%;
    }
}

.modal-view{
    background-color: #272727;
    width: 400px;
    padding: 32px;
    text-align: center;

    display: flex;
    flex-direction: column;
    gap: 20px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button{
    p {
        font-size: 25px !important;
    }
}

.result-controller{
    padding: 21px 24px;
    width: 100%;
    height: 60px;
    background: rgba(#000, .25);
    border: 1px solid rgba(113, 113, 113, 0.2);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.placeholder{
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0%, -50%);
}

.input-container{
    position: relative;
}


.input-container>label {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    // display: none;
    z-index: 1;

    @media screen and (max-width: 600px) {
        position: absolute;
        left: 16px;
        top: 23%;
    }
}

.input-container>input[type=text]:placeholder-shown+label {
    display: block;
}

.label{
    display: flex;
    flex-direction: row;
    gap: 8px;
    cursor: pointer;
    user-select: none;
}

.center-trade {
    padding: 0;
    margin-top: -10px;
    margin-bottom: -20px !important;
}

.nft-layout{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 40px 24px;

    main{
        height: 100%;
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

.imageCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px; 
    position: relative;

    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0px 0px 8px #d7d7d7;
    border-radius: 10px;

    .check-mark {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.active-cell {
    outline: 2px solid #2D6E94;
    .caption {
        color: #2D6E94;
    }

    .check-mark {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
  
  .image {
    border-radius: 6px;
    height: 100px;
    width: 100px;
    object-fit: cover;
  }
  
  .caption {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    color: #666;
  }

.check-mark {
    svg {
        path {
            fill: #2D6E94;
        }
    }
}

.empty-controller {
    height: 100%;
    text-align: center;
}

.claim-btn {
    width: 200px;
}

.notif {
    flex-direction: row;

    @media screen and (max-width: $smWidthBreakpoint) {
        flex-direction: column;
        align-items: center;
        gap: 20px;

        padding: 10px;
        width: calc(100% - 20px);
        border-radius: 12px;
        border: 1px solid #6bc4d29e;
    }
}