.custom-bg-modal {
    
    & .MuiDialog-paperWidthSm {
        background-color: #161E26  !important;
        border-radius: 14px !important;
        background-image: none !important;
        padding: 20px !important;
        width: 300px !important;
    }
}

.custom-bg-modal-transfer {
    
    & .MuiDialog-paperWidthSm {
        background-color: whitesmoke;
        border-radius: 14px !important;
        background-image: none !important;
        padding: 10px !important;
        width: 400px !important;
    }
}
