@import './assets/styles/vars';
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC&family=Space+Grotesk:wght@300;400;500;600;700;800");

body {
    background-color: #fafbfd !important;
    color: white;
    font-family: 'Montserrat', sans-serif;
    margin: 0px;
    // font-family: 'Roboto, sans-serif',
}

.page-layout{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1700px;
    width: 100%;
    margin: 0 auto;
}

.bg-video{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    filter: grayscale(90%);
    -webkit-filter: grayscale(90%);
    opacity: 50%;

    @media screen and (max-width: 991px) {
        object-fit: cover;
    }
}

.bg-video-black{
    @extend .bg-video;
    filter: grayscale(90%);
    -webkit-filter: grayscale(90%);
    opacity: 50%;
}

.MuiPagination-root {
    letter-spacing: none !important;
    button {
        background-color: $dark;
        border: none;
        letter-spacing: none !important;
    }

    .Mui-selected {
        background-color: #acf0ff6e !important;
    }
}

.MuiPaginationItem-root {
    letter-spacing: 1px !important;
}

.container{
    flex: 1;
    height: 100%;
}

.t-table-holder{
    overflow-y: auto;
    margin-top: 10px;
    height: calc(100vh - 320px);
    overflow-x: hidden;
    scrollbar-width: none;
}

.overflow-text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}

.link{
    text-decoration: underline;
    color: #acf0ff;
    cursor: pointer;

}

.card-layout{
    background-color: #272727 !important; 
    width: 500px;
    background-image: none !important;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 32px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;

    :focus{
        outline: 0;
    }
}

.mobile-modal{
    @extend .card-layout;
    width: 100%;
    height: auto;
    max-width: 500px;
    max-height: 750px;
    position: relative;
    padding: 70px 40px;
    transition: 0.3s;

    :focus{
        outline: 0;
    }

    // @media screen and (max-width: 600px) {
    //     width: 100%;
    //     height: 100%;
    //     max-width: 100%;
    //     padding: 70px 32px;
    //     max-height: max-content;
    // }
}

.MuiTooltip-tooltip{
    letter-spacing: 2px;
    font-size: 16px !important;
}

.custom-input .MuiInputBase-root {
 letter-spacing: 2.5px;
}

.off-white-container {
    background-color: #fff; /* This is an off-white color, you can change it to your preferred shade of off-white */
    padding: 1px; /* Adjust the padding as needed */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
}

.error-container {
    // background-color: #6bc5d2 !important;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    min-width: 320px;
    align-items: center;
    padding: 50px 20px;
    border: 1px solid #e4e4e6;

    h4 {
        font-weight: 700;
    }

    h6 {
        font-size: 15px;
    }

    img {
        height: 56px;
        width: 56px;
        margin-bottom: 10px;
    }
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
}
.sticky {
    position: sticky;
    top: 0;
    z-index: 999;
}

.input-reset .MuiSelect-select{
    padding-top: 7px !important;
    font-size: 13px;
    box-shadow: none !important;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
}

.settings-input .MuiFormLabel-root {
    top: -3px;
}