$white: #fff;
$light-grey: #DBDBDB;
$grey: #717171;
$dark-grey: #383838;
$dark: #272727;
$light-blue: #ADF0FF;
$red: #E03554;
$shadow: 1px 8px 8px #17171790;

$xsWidthBreakpoint: 0px;
$smWidthBreakpoint: 600px;
$mdWidthBreakpoint: 900px;
$lgWidthBreakpoint: 1200px;
$xlgWidthBreakpoint: 1536px;


.white {
    color: $white;
}
.light-grey {
    color: $light-grey;
}
.grey {
    color: $grey;
}
.dark-grey {
    color: $dark-grey;
}
.dark {
    color: $dark;
}
.light-blue {
    color: $light-blue;
}
.red {
    color: $red;
}