@import '../../assets/styles/vars';

.header-layout{
    padding: 12px 32px;
    align-items: center;

    img {
        object-fit: cover;
    }

    @media screen and (max-width: $lgWidthBreakpoint) {
        padding: 12px;
    }
}

.menu-icon {
    width: 48px;
    height: 48px;
    padding-top: 14px !important;
}

.menu-logo {
    img {
        cursor: pointer;
    }
}

.layout{
    padding: 3rem 28px;

    display: flex;
    flex-direction: column;
    gap: 20px;

}

.mobile-logo {
    display: flex;
    justify-content: center;
}

.outlined-button{
    border: 2px solid #717171 !important;
    position: relative;
    border-radius: 0px !important;
    padding: 9px 42px !important;
}

.menu-controller{
    justify-content: center;
    height: auto;
    gap: 18px;
    height: 100%
}

.menu-controller-mobile {
    @extend .menu-controller;
    height: auto;
    justify-content: flex-start;
    padding: 20px 16px;
    gap: 32px !important;

    & .header-link {
        color: #a9a9b5;
    }
}

.header-link{
    text-transform: uppercase !important;
    color: $grey ;
    cursor: pointer;
    width: auto;

    :hover {
        transition: 0.4s;
        color: $white;
    }
}

.mobile-menu{
    background-color: #383838a2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    padding: 48px 28px;
}

.outlined-button-absolute{
    @extend .outlined-button;
    // position: absolute !important;
    width: 100% !important;
    background-color: #2D6E94 !important;
    // top: 80px !important;
    // left: 0px !important;
}

.snackbar{
    background-color: #333333 !important;
    color: white !important;
    text-transform: uppercase;
    text-align: center;
    font-weight: 800;
    font-family: 'Roboto, sans-serif' !important;
    
    line-height: 30px;
    align-items: center;
    // height: 28px;
    margin-top: -16px;
}

.popover{
    margin-top: 20px;
}

.desc{
    position: absolute;
    bottom: -20px;
}

.background-selector{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: unset;
}

.logo {
    margin-right: 30px;
  }

.logo-container {
    display: flex;
    align-items: center;
    text-align: center;

    color: black;
}

.header-button {
    color: black;
    width: 200px;
    gap: 16px;
}

.side-menu {
    width: 310px;
    padding-top: 32px;
}

.connected-stack {
    height: 40px !important;
    margin-bottom: 20px;
    
    @media screen and (min-width: $smWidthBreakpoint) and (max-width: $lgWidthBreakpoint)  {
        height: calc(100vh - 480px) !important;
    }
}

.divider-custom {
    @media screen and (max-width: $smWidthBreakpoint) {
        display: none;
    }
}

.divider-custom-2 {
    display: none;

    @media screen and (max-width: $smWidthBreakpoint) {
        display: unset;
    }
}

.content-control {
    @media screen and (min-width: $smWidthBreakpoint) and (max-width: $lgWidthBreakpoint) {
        display: flex;
        flex-direction: column;
    }

    @media screen and (max-width: $smWidthBreakpoint) {
        display: flex;
        flex-direction: column-reverse;
    }
}

.active {
   color: #2D6E94;

   svg {
    path {
        fill: #2D6E94;
    }
   }
}