@import '../../assets/styles/vars';

.home-layout{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    main{
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 750px;
      flex: 1;
      padding: 32px 40px;

      @media screen and (max-width: $smWidthBreakpoint) {
        padding: 20px;
      }
    }
}

.settings-card {
  padding: 20px 0px;
  background-color: transparent;
  margin: 20px 0px;
}

.save {
  border-radius: 10px;
  box-shadow: none;
  color: white;
  font-weight: 700;
  padding-left: 32px;
  padding-right: 32px;
}

.settings-cell {
  width: 100%;
  padding: 10px 0px;
  margin: 16px 0px;

  .interaction {
    margin: 10px 0px;
  }
}